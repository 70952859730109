// =================================================
// VARIABLES LESS
// =================================================

// Colors
// -------------------------------------------------
$global-text-color: #000000;
$global-text-color-light: #707070;
$faded: #f5f5f5;
$muted: #ededed;
$white: #ffffff;
$dark: #000000;
$danger: #e52e58;
$global-placeholder: #cbcbcb;
$img-hover-border: #92c0f4;

$primary: var(--primary);
$text-primary: #ffffff;
$secondary: var(--secondary);
$text-secondary: #000000;

// Text & Font
// -------------------------------------------------
// $global-font-size: 14px;
$global-font-size: var(--global-font-size);
$global-font-family: "Roboto-Regular", sans-serif;
$roboto-light: "Roboto-Light", sans-serif;
$roboto-bold: "Roboto-Bold", sans-serif;

// Border
// -------------------------------------------------
$global-border-color: $muted;

// Points
// -------------------------------------------------
$screen-small: 768px;
$screen-medium: 992px;
$screen-large: 1200px;
$screen-xlarge: 1600px;
$screen-small-max: ($screen-small - 1);
$screen-medium-max: ($screen-medium - 1);
$screen-large-max: ($screen-large - 1);
$screen-xlarge-max: ($screen-xlarge - 1);
