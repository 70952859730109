.use-mcp-style-attr-selector {
  .form-control:focus {
    border: 1px solid blue !important;
  }
  .attribute-selector {
    .btn-link,
    label {
      color: #000000 !important;
    }
    .btn.btn-default {
      color: #000000 !important;
      border: 1px solid #000000 !important;
      &:hover {
        background: $primary;
        color: $text-primary !important;
      }
    }
    .btn.btn-default.disabled {
      &:hover {
        color: #000000 !important;
      }
      color: #000000 !important;
    }
    .btn.btn-default.active {
      &:hover {
        background: $primary;
        color: $text-primary !important;
      }
      background: $primary;
      color: $text-primary !important;
    }

    .input-list {
      > div {
        background-color: #f8f8f8;
        padding: 20px;
      }
    }
    .form-group {
      padding: 0px;
    }
    .react-select-wrapper.form-group {
      padding: 20px;
      label {
        position: initial;
        margin-bottom: 0px;
      }
    }
    .react-select-wrapper.form-group.form-group-active {
      label {
        font-size: 1.125rem;
      }
    }
  }
}
